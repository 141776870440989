import axios from 'axios';
import { isDevelopment } from './util';
import i18n from 'i18next';
import i18next from 'i18next';

// TODO: try to use api.findmyvino.com somehow
const Domain = isDevelopment() ? 'http://localhost:8080' : 'https://pick4me-396801.uc.r.appspot.com';
const WineRecsPath = `${Domain}/v1/wine_recs`;

interface Suggestion {
    name: string;
    // country: string;
    region: string;
    appellation: string;
    type: "red" | "white" | "sparkling" | "rose";
    description: string;
    metadata?: Record<string, string>,
    wineSearcherUrl?: string;
    winecomUrl?: string;
    klWinesUrl?: string;
}

export interface WineRecsResponse {
    suggestions: Suggestion[]
}


const MOCK_RESPONSE: WineRecsResponse = {
    "suggestions": [
        {
            "name": "Chardonnay",
            //"country": "Turkey",
            "region": "Aegean",
            "appellation": "",
            "type": "white",
            "description": "A crisp and refreshing white wine with flavors of green apple, pear, and citrus. It pairs well with seafood and light appetizers.",
            "wineSearcherUrl": "https://www.wine-searcher.com/find/Chardonnay%20Aegean//usa?Xsort_order=d"
        },
        {
            "name": "Riesling",
            //"country": "Germany",
            "region": "Mosel",
            "appellation": "",
            "type": "white",
            "description": "An aromatic white wine with notes of peach, apricot, and honey. It has a hint of sweetness which balances the spicy flavors of Cevizli Ezme.",
            "wineSearcherUrl": "https://www.wine-searcher.com/find/Riesling%20Mosel//usa?Xsort_order=d"
        },
        {
            "name": "Grenache",
            //"country": "Spain",
            "region": "Rioja",
            "appellation": "",
            "type": "red",
            "description": "A medium-bodied red wine with flavors of red cherry, plum, and spices. It has a smooth and velvety texture, making it a great match for the rich and flavorful Cevizli Ezme.",
            "wineSearcherUrl": "https://www.wine-searcher.com/find/Grenache%20Rioja//usa?Xsort_order=d"
        },
        {
            "name": "Sparkling Rosé",
            //"country": "France",
            "region": "Champagne",
            "appellation": "",
            "type": "sparkling",
            "description": "A refreshing and lively sparkling wine with notes of strawberry, raspberry, and citrus. Its effervescence makes it a perfect companion for the vibrant flavors of Cevizli Ezme.",
            "wineSearcherUrl": "https://www.wine-searcher.com/find/Sparkling%20Ros%C3%A9%20Champagne//usa?Xsort_order=d"
        }
    ]
};

const DUCK_MOCK_RESPONSE: WineRecsResponse = {
    "suggestions": [
        {
            "name": "Pinot Noir",
            //"country": "France",
            "region": "Burgundy",
            "appellation": "Côte de Nuits",
            "type": "red",
            "description": "A light-bodied and elegant red wine with notes of red berries, cherry, and earthiness. Its acidity and smooth tannins make it a great pairing for the rich flavors of Peking Duck.",
            "wineSearcherUrl": "https://www.wine-searcher.com/find/Pinot%20Noir%20C%C3%B4te%20de%20Nuits/usa?Xsort_order=d"
        },
        {
            "name": "Gewürztraminer",
            //"country": "Germany",
            "region": "Alsace",
            "appellation": "Alsace Grand Cru",
            "type": "white",
            "description": "An aromatic and medium-bodied white wine with floral notes, lychee, and exotic spices. The sweetness and acidity of Gewürztraminer complement the sweetness and tanginess of the plum sauce.",
            "wineSearcherUrl": "https://www.wine-searcher.com/find/Gew%C3%BCrztraminer%20Alsace%20Grand%20Cru/usa?Xsort_order=d"
        },
        {
            "name": "Champagne",
            //"country": "France",
            "region": "Champagne",
            "appellation": "",
            "type": "sparkling",
            "description": "A lively and effervescent sparkling wine with refreshing acidity and flavors of citrus, apple, and toast. The crisp bubbles and acidity of Champagne cleanse the palate and cut through the richness of Peking Duck.",
            "wineSearcherUrl": "https://www.wine-searcher.com/find/Champagne%20Champagne/usa?Xsort_order=d"
        },
        {
            "name": "Rosé",
            //"country": "Provence",
            "region": "",
            "appellation": "",
            "type": "rose",
            "description": "A dry and refreshing rosé with delicate flavors of strawberry, watermelon, and citrus. The light and fruity characteristics of rosé pair well with the flavors of Peking Duck.",
            "wineSearcherUrl": "https://www.wine-searcher.com/find/"
        }
    ]
};

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export async function genRecs(meal: string, debug: boolean = false): Promise<WineRecsResponse> {
    if (debug) {
        await sleep(2000 + Math.random() * 1000)
        return [MOCK_RESPONSE, DUCK_MOCK_RESPONSE][Math.floor(Math.random() * 2)];
    } else {

        return (await axios.get<WineRecsResponse>(WineRecsPath, {
            params: {
                dish: meal,
                language: i18next.language,
            }
        })).data;
    }
}