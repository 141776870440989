
type EventType = 'ext_link_click' |
    'feedback_link' |
    'install_prompt_click' |
    'install_prompt_success' |
    'install_prompt_dismissed' |
    'food_pairing_search_init';

interface ExtLinkMetadata {
    link: string;
}
type EventPayload = ExtLinkMetadata | undefined;

export function typedGtag(event: EventType, payload?: EventPayload) {
    try {
        // @ts-ignore
        gtag('event', event, payload);
    } catch (e) {

    }
}

export function logOutboundLinkClick(link: string) {
    typedGtag('ext_link_click', { link: link });
}