import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { isDevelopment } from './util';
import { initReactI18next } from 'react-i18next';

import enTranslations from './translations/en.json';
import esTranslations from './translations/es.json';
import frTranslations from './translations/fr.json';
import itTranslations from './translations/it.json';

export function initI18n() {
    i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            fallbackLng: 'en',
            debug: isDevelopment(),
            resources: {
                en: {
                    translation: enTranslations,
                },
                es: {
                    translation: esTranslations,
                },
                fr: {
                    translation: frTranslations,
                },
                it: {
                    translation: itTranslations,
                }
            }
        })
}
;
