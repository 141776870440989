import Container from '@mui/material/Container';
import './App.css';
import { Helmet } from 'react-helmet';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { WineRecsResponse, genRecs } from './api';
import Alert from '@mui/material/Alert';
import AssistantIcon from '@mui/icons-material/Assistant';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ReactComponent as LogoSvg } from './logo.svg';
import SvgIcon from '@mui/material/SvgIcon';
import Footer from './footer';
import Link from '@mui/material/Link';
import { logOutboundLinkClick, typedGtag } from './analytics';
import { initI18n } from './i18n';
import { useTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { CountryFlag } from './countryFlag';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

initI18n();
type CallState = {
  kind: "init";

} | {
  kind: "loading";

} | {
  kind: "success";
  response: WineRecsResponse;
} | {
  kind: "error";
  error: any
};

function App() {

  const [callState, setCallState] = useState<CallState>({ kind: "init" });
  const [prompt, setPrompt] = useState<string>('');
  const { t } = useTranslation();


  return (<>

    <Helmet>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap"
      />
      <title>FindMyVino</title>
      {/* Google tag (gtag.js)  */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-QK7M6B8S5P"></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-QK7M6B8S5P');
        gtag('config', 'AW-969609399');`}
      </script>
    </Helmet>

    <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh)' }}>
      <Container maxWidth="sm" sx={{ flex: '1 0 auto' }}>
        <Grid
          container
          spacing={2}
          paddingTop={3}
        >
          <Grid xs={12} paddingBottom={1} paddingTop={2}>
            <Box display={'flex'}
              alignItems={'baseline'}
              justifyContent={'center'}
              paddingBottom={2}>
              <Typography paddingRight={2}>
                <SvgIcon fontSize='large'>
                  <LogoSvg />
                </SvgIcon>
              </Typography>
              <Typography
                variant="h3"
                textAlign={'center'}>

                FindMyVino
              </Typography>
            </Box>
          </Grid>

          <Grid xs={12} alignContent={'center'}>
            <TextField
              fullWidth
              label={t("meal")}
              variant="outlined"
              inputProps={{ maxLength: 256 }}
              onChange={(e) => {
                setPrompt(e.target.value);
              }} />

          </Grid>

          <Grid xs={4}>
            <Item hidden>xs=2</Item>
          </Grid>
          <Grid xs={4}
            alignContent={'center'}
            paddingTop={2} paddingBottom={2}>
            <Button
              fullWidth
              variant="contained"
              startIcon={<AssistantIcon />}
              disabled={callState.kind !== "loading" && prompt.trim().length === 0}
              onClick={async (_e) => {
                setCallState({ kind: "loading" });
                typedGtag('food_pairing_search_init');
                try {
                  const res = await genRecs(prompt);
                  setCallState({ kind: "success", response: res });
                } catch (error) {
                  console.error(error, "Failed to get response");
                  setCallState({ kind: "error", error: error })
                }
              }}>
              {t('find.it.cta')}
            </Button>

          </Grid>
          <Grid xs={4}>
            <Item hidden>xs=2</Item>
          </Grid>
          {callState.kind === "loading" ?
            <Grid xs={12}>
              <LinearProgress />
            </Grid> : null
          }
          {callState.kind === "error" ?
            <Grid xs={12}>
              <Alert severity="error">{t('recs_fetching_error')}</Alert>
            </Grid> : null}
          {callState.kind === "success" ?
            callState.response.suggestions.map((sug) => {
              return <Grid xs={12} paddingBottom={2}>
                <Card>
                  <CardContent>
                    <Stack
                      direction="row" justifyContent={"space-between"} spacing={1} paddingBottom={1}>
                      <Stack
                        direction='row'
                        justifyContent={'left'}
                        spacing={1}>
                        {sug.metadata?.country ?
                          <CountryFlag country={sug.metadata?.country || 'fr'} />
                          : null}
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.primary"
                          alignItems={'center'}
                          gutterBottom>
                          {sug.name} - {sug.region}
                        </Typography>
                      </Stack>

                      <Typography
                        align='right'
                        color="text.primary"
                      >

                        <Chip
                          label={sug.type}
                          variant='outlined'
                          style={{
                            backgroundColor: sug.type === 'red' ? '#B11226' : sug.type === 'white' ? '#EEEDC4' : '#EFE1A1',
                            color: sug.type === 'red' ? 'white' : 'black',
                          }} />
                      </Typography>
                    </Stack>

                    <Box>

                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      {sug.description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {sug.wineSearcherUrl ? <Chip
                      icon={<OpenInNewIcon />}
                      component='a'
                      href={sug.wineSearcherUrl}
                      target='_blank'
                      size='small'
                      onClick={(_e) => {
                        logOutboundLinkClick(sug.wineSearcherUrl || 'N/A');
                      }}
                      label='Wine Searcher'
                    /> : null}
                    {sug.winecomUrl ? <Chip
                      icon={<OpenInNewIcon />}
                      component='a'
                      href={sug.winecomUrl}
                      size='small'
                      label='Wine.com'
                      onClick={(_e) => {
                        logOutboundLinkClick(sug.winecomUrl || 'N/A');
                      }} /> : null}
                    {sug.klWinesUrl ? <Chip icon={<OpenInNewIcon />}
                      component='a'
                      href={sug.klWinesUrl}
                      size='small'
                      label='KLWines'
                      onClick={(_e) => {
                        logOutboundLinkClick(sug.klWinesUrl || 'N/A');
                      }}
                    /> : null}
                  </CardActions>
                </Card>
              </Grid>
            }) : null
          }
          {callState.kind === "success" || callState.kind === "error" ?
            <Box justifyContent={'center'} width={'100%'}>
              <Typography textAlign={'center'} variant='body2' paddingBottom={2} paddingTop={2} >
                <Link target='_blank'
                  href='https://docs.google.com/forms/d/e/1FAIpQLSenyuEl4sJyY3nvGndWNO1Y3n-QfL0a5RsORWmxh-6RnJ7hHw/viewform?usp=sf_link'
                  onClick={(_e) => {
                    typedGtag('feedback_link');
                  }}>
                  {t('feedback_cta')}
                </Link>
              </Typography></Box>
            : null}
        </Grid>
      </Container>
      <Footer />
    </Box>
  </>);
}

export default App;
