import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

export default function Footer() {
    return (
        <Box
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                        ? theme.palette.grey[200]
                        : theme.palette.grey[800],
                p: 2,
                flexShrink: 0,
                width: '100%',
            }}
            component="footer"
        >
            <Container maxWidth="sm">

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: 'fit-content',
                        alignContent: 'baseline',
                    }}
                >
                    <Box paddingRight={2}>
                        <Typography variant="body2" color="text.secondary" align="center">
                            {"Copyright © "}
                            <Link color="inherit" href="https://findMyVino.com/">
                                FindMyVino
                            </Link>{" "}
                            {new Date().getFullYear()}
                            {"."}
                        </Typography>
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    <Box paddingLeft={2} paddingRight={2}>
                        <Typography variant="body2" color="text.secondary" align="center">
                            <Link color="inherit" href="https://findMyVino.com/privacypolicy.html">
                                {'Privacy Policy'}
                            </Link>
                        </Typography>
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    <Box paddingLeft={2} >
                        <Typography variant="body2" color="text.secondary" align="center">
                            <Link color="inherit" href="https://findmyvino.com/tos.html">
                                {'Terms of Service'}
                            </Link>
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}